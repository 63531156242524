/*
/ FORMS
 */
:root {
    --url1: #E44184;
    --url2: #6822B0;
    --url3: #32B9F1;
    --text-color: #1A1A1A;
    --secondary-color: #3D89AE;
    --note-color: #b0bbc1;
    --bg-color-main: #ffffff;
    --bg-color-secondary: #F7F7F7;
    --bg-color-third: #E6EDF6;
}

input, select, textarea, button, .button-alt, .button-blue  {
    height: 25px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box;
    box-sizing:content-box;
    padding : 5px 15px;
    border-radius: 4px;
    border: 1px solid var(--bg-color-third);
    outline: none;
}

input {
    width: 150px;
}

button {
    background-color: var(--url1);
    color: var(--bg-color-main);
    cursor: pointer;
}

.button-blue {
    background-color: var(--url3);
    color: var(--bg-color-main);
    cursor: pointer;
}

.button-alt {
    background-color: var(--bg-color-main);
    color: var(--url3);
    border: 1px solid var(--url3);
    cursor: pointer;
}

.button-link {
    height: 25px;
    color: var(--url3);
    text-decoration: underline;
    box-sizing:content-box;
    padding : 5px 15px;
    border-radius: 4px;
    border: 0 solid var(--bg-color-main);
    cursor: pointer;
    background-color: unset;
    outline: none;

}

.button-ico {
    outline: none;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0;
    background-color: var(--bg-color-main);
    padding-top: 5px;
    cursor: pointer;

}

.button-alt:disabled {
    cursor: auto;
    opacity: 0.3;
}

select {
    background: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E) no-repeat;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-size: .65em;
    background-position: calc(100% - 1em) center;
}

select::-ms-expand {
    display: none;
}

input:focus, select:focus, textarea:focus {
    outline: none;
    border: 1px solid var(--url3);
}

textarea {
    resize: none;
    height: 100px;
}

.btn-diabled {
    pointer-events: none;
}


.custom-check span {
    font-size: 24px;
}

.custom-check input {
    width: 20px;
    height: 20px;
}

.checkbox-container {
    display:inline-flex;
    margin-right: 10px;
    width: 40px;
    height: 26px;
    padding: 2px;
    border: var(--url3) 1px solid;
    background-color: var(--bg-color-main);
    cursor: pointer;
}

.checkbox-checked {
    justify-content: flex-end;
    background-color: var(--url3);
}

.checkbox-slider {
    width: 20px;
    height: 20px;
    background-color: var(--bg-color-main);
    border: var(--url3) 1px solid;

}

/*
/ MEDIA
 */

