@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


* { margin: 0; padding: 0; }

:root {
    --max-width: 1240px;

    --url-color: #EC635D;
    --text-color: #3a3a3a;
    --bg-color-main: #F8F8F8;
    --bg-color-secondary: #FFFFFF;

    --main-heading-text-size: 50px;
    --heading-text-size: 40px;
    --subheading-text-size: 26px;
    --section-text-size: 20px;
    --main-text-size: 15px;

    --main-padding: 30px;
    --half-padding: 15px;
}

body {
    background-color: var(--bg-color-main);
    font-family: 'Open Sans', SansSerif, serif;
    font-weight: 400;
    color: var(--text-color);
    line-height: 1.4;
    font-size: var(--main-text-size);
}

h1, h2, h3, h4 {
    font-family: 'Montserrat', 'Open Sans', SansSerif, serif;
    font-weight: 700;
}

h1 {
    font-size: var(--main-heading-text-size);
}

h2 {
    font-size: var(--heading-text-size);
}

h3 {
    font-size: var(--subheading-text-size);
}

h4 {
    font-size: var(--section-text-size);
}

p {
    font-size: var(--main-text-size);
}

a {
    color: var(--url-color);
}

div, p {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.separator {
    height: 10px;
    border-bottom: #e0e0e0 1px solid;
}

.displayMobile {
    display: none;
}

.displayWeb {
    display: block;
}

.cursorDefault {
    cursor: default;
}

.cursorPointer {
    cursor: default;
}


/*
/ MAIN LAYOUT
 */

.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.container {
    width: 100%;
    max-width: var(--max-width);
    padding: 0 var(--main-padding);
}

.tile {
    padding: var(--main-padding);
    width: 280px;
    border: #eeeeee 1px solid;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    margin-right: 50px;
    margin-bottom: 50px;
}


/*
/ Media Queries
 */

@media (min-width: 0px) and (max-width:980px) {

}

@media (min-width: 450px) and (max-width:600px) {
    :root {
        --main-heading-text-size: 40px;
        --heading-text-size: 30px;
        --section-text-size: 18px;
        --subheading-text-size: 20px;
        --main-padding: 20px;
    }
}

@media (min-width: 0px) and (max-width:450px) {
    :root {
        --main-heading-text-size: 40px;
        --heading-text-size: 30px;
        --section-text-size: 16px;
        --subheading-text-size: 15px;
        --main-text-size: 14px;
        --main-padding: 20px;
        --half-padding: 10px;
    }

    .displayMobile {
        display: block;
    }

    .displayWeb {
        display: none;
    }

}

@media (min-width: 0px) and (max-width:330px) {
    :root {
        --logo-text-size: 30px;
        --heading-text-size: 20px;
        --subheading-text-size: 15px;
        --main-text-size: 13px;
        --main-padding: 15px;
        --half-padding: 8px;
    }

}
